import React, { useState, useEffect } from 'react';

const TutorialButton = ({ onClick }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: window.innerWidth - 56, y: window.innerHeight - 56 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMove = (e) => {
      if (!isDragging) return;
      
      e.preventDefault();
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      
      const newX = Math.min(Math.max(0, clientX - dragOffset.x), window.innerWidth - 48);
      const newY = Math.min(Math.max(0, clientY - dragOffset.y), window.innerHeight - 48);
      
      setPosition({ x: newX, y: newY });
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', handleEnd);
      window.addEventListener('touchmove', handleMove, { passive: false });
      window.addEventListener('touchend', handleEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, dragOffset]);

  const handleStart = (e) => {
    const isTouch = e.type === 'touchstart';
    if (isTouch) {
      const touch = e.touches[0];
      setIsDragging(true);
      const rect = e.currentTarget.getBoundingClientRect();
      setDragOffset({
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top
      });
    } else {
      e.preventDefault();
      setIsDragging(true);
      const rect = e.currentTarget.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  };

  return (
    <button 
      className="tutorial-fab"
      onClick={isDragging ? undefined : onClick}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
      style={{
        position: 'fixed',
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
        transform: isDragging ? 'scale(1.1)' : 'scale(1)',
        touchAction: 'none'
      }}
    >
      <span style={{ fontSize: '24px', pointerEvents: 'none' }}>🤘</span>
    </button>
  );
};

export default TutorialButton; 