import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';

const GrouplingsGraph = ({ data, onNodeClick, isMobile }) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const navigate = useNavigate();

  // Define bacon-inspired colors
  const baconColors = [
    '#8B4513', // SaddleBrown
    '#A0522D', // Sienna
    '#CD853F', // Peru
    '#DEB887', // BurlyWood
    '#D2691E', // Chocolate
    '#E27B36', // Custom orange
  ];

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        const width = Math.min(containerWidth, 600);
        const height = isMobile ? window.innerHeight * 0.5 : width;
        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [isMobile]);

  useEffect(() => {
    if (!data || !data.relatedGroups || data.relatedGroups.length === 0 || dimensions.width === 0) {
      console.log('Skipping graph render due to missing data or dimensions:', { data, dimensions });
      return;
    }

    const { width, height } = dimensions;

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', `0 0 ${width} ${height}`);

    svg.selectAll('*').remove();

    const g = svg.append('g');

    const zoom = d3.zoom()
      .scaleExtent([0.5, 5])
      .on('zoom', (event) => {
        g.attr('transform', event.transform);
      });

    svg.call(zoom);

    const maxCount = d3.max(data.relatedGroups, d => d.count);
    const minCount = d3.min(data.relatedGroups, d => d.count);

    const radiusScale = d3.scaleSqrt()
      .domain([minCount, maxCount])
      .range([Math.min(width, height) * 0.05, Math.min(width, height) * 0.15]);

    const forceScale = d3.scaleLinear()
      .domain([minCount, maxCount])
      .range([-50, -150]);

    const simulation = d3.forceSimulation(data.relatedGroups)
      .force('charge', d3.forceManyBody().strength(d => forceScale(d.count)))
      .force('center', d3.forceCenter(width / 2, height / 2).strength(0.1))
      .force('collision', d3.forceCollide().radius(d => radiusScale(d.count) + 2))
      .force('x', d3.forceX(width / 2).strength(0.1))
      .force('y', d3.forceY(height / 2).strength(0.1))
      .alphaDecay(0.01) // Slower decay for more stable settling
      .velocityDecay(0.3)
      .on('tick', ticked);

    const node = g.selectAll('.node')
      .data(data.relatedGroups)
      .enter().append('g')
      .attr('class', 'node')
      .call(d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended));

    node.append('circle')
      .attr('r', d => radiusScale(d.count))
      .style('fill', (d, i) => baconColors[i % baconColors.length])
      .style('opacity', 0.7)
      .on('click', handleNodeClick);

    node.append('text')
      .attr('dy', '.3em')
      .style('text-anchor', 'middle')
      .style('font-size', d => `${Math.max(8, radiusScale(d.count) / 3)}px`)
      .text(d => d.name)
      .style('pointer-events', 'none');

    function ticked() {
      node.attr('transform', d => {
        d.x = Math.max(radiusScale(d.count), Math.min(width - radiusScale(d.count), d.x));
        d.y = Math.max(radiusScale(d.count), Math.min(height - radiusScale(d.count), d.y));
        return `translate(${d.x},${d.y})`;
      });
    }

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    // Initial positions
    data.relatedGroups.forEach((d, i) => {
      const angle = (i / data.relatedGroups.length) * 2 * Math.PI;
      const radius = Math.min(width, height) / 4;
      d.x = width / 2 + radius * Math.cos(angle);
      d.y = height / 2 + radius * Math.sin(angle);
    });

    simulation.nodes(data.relatedGroups);

    // Run the simulation for a few iterations before rendering
    for (let i = 0; i < 300; ++i) simulation.tick();

    ticked(); // Apply the final positions

    svg.call(zoom.transform, d3.zoomIdentity);

  }, [data, onNodeClick, dimensions, navigate, baconColors]);

  const handleNodeClick = (event, d) => {
    if (d && d.id) {
      navigate(`/artist/${d.id}`);
      onNodeClick(d.id);
    } else {
      console.error('Node clicked without an id:', d);
    }
  };

  return (
    <div 
      ref={containerRef} 
      style={{ 
        width: '100%', 
        maxWidth: '600px', 
        height: dimensions.height,
        margin: '5px auto 0',
        touchAction: 'none',
        position: 'relative'
      }}
    >
      <svg 
        ref={svgRef} 
        style={{ 
          width: '100%',
          height: '100%',
          display: 'block',
          margin: '0 auto'
        }}
      ></svg>
    </div>
  );
};

export default GrouplingsGraph;
