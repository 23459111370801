import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

import Graph from '../components/visualization/Graph';
import SearchBar from '../components/search/SearchBar';
import ArtistDetails from '../components/ArtistDetails';
import GraphToggle from '../components/visualization/GraphToggle';
import { useMusicBrainz } from '../hooks/useMusicBrainz';
import { useVisualization } from '../hooks/useVisualization';
import { calculateArtistRanking } from '../utils/artistRanking';
import GrouplingsGraph from '../components/GrouplingsGraph';

const Visualization = ({ onVisualizationLoad, showTutorial }) => {
  const [error, setError] = useState(null);
  const musicBrainz = useMusicBrainz();
  const visualization = useVisualization();
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const [showWelcome, setShowWelcome] = useState(true);
  const [activeNodeId, setActiveNodeId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeArtist, setActiveArtist] = useState(null);
  const [isModalExpanded, setIsModalExpanded] = useState(false);
  const [spotifyArtistName, setSpotifyArtistName] = useState('');
  const { artistId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isArtistsView, setIsArtistsView] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [hasShownTutorial, setHasShownTutorial] = useState(false);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const currentArtistId = pathParts[pathParts.length - 1];
    if (currentArtistId && currentArtistId !== activeNodeId) {
      handleSearch(currentArtistId);
    }
  }, [location, activeNodeId]);

  useEffect(() => {
    if (artistId) {
      handleSearch(artistId);
    }
  }, [artistId]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const width = Math.min(containerRef.current.offsetWidth, 600);
        const height = window.innerHeight * 0.5; // Set to 50% of viewport height for both mobile and desktop
        setContainerSize({ width, height });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [isMobile]);

  useEffect(() => {
    if (activeArtist && !hasShownTutorial) {
      const hasSeenTutorial = localStorage.getItem('hasSeenVisualizationTutorial');
      if (!hasSeenTutorial) {
        console.log('Artist loaded, triggering tutorial');
        onVisualizationLoad && onVisualizationLoad();
      }
      setHasShownTutorial(true);
    }
  }, [activeArtist, onVisualizationLoad, hasShownTutorial]);

  const filterImmediateRelationships = useCallback((graphData, activeId) => {
    if (!activeId) return graphData;

    const immediateNodes = new Set([activeId]);
    graphData.links.forEach(link => {
      if (link.source === activeId) immediateNodes.add(link.target);
      if (link.target === activeId) immediateNodes.add(link.source);
    });

    return {
      nodes: graphData.nodes.filter(node => immediateNodes.has(node.id)),
      links: graphData.links.filter(link => 
        immediateNodes.has(link.source) && immediateNodes.has(link.target)
      )
    };
  }, []);

  const handleSearch = useCallback(async (termOrId) => {
    try {
      setError(null);
      setIsLoading(true);
      visualization.resetGraphData();
      let artistId = termOrId;
      
      if (typeof termOrId === 'string' && !termOrId.match(/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/)) {
        const searchResults = await musicBrainz.searchArtist(termOrId);
        if (searchResults.length > 0) {
          artistId = searchResults[0].id;
        } else {
          setError('Artist or group not found');
          setShowErrorModal(true);
          setIsLoading(false);
          return;
        }
      }

      const details = await musicBrainz.getArtistDetails(artistId);
      if (!details) {
        setError('Unable to fetch artist details');
        setShowErrorModal(true);
        setIsLoading(false);
        return;
      }

      visualization.updateGraphData(details);
      setActiveNodeId(artistId);
      setActiveArtist(details);
      setSpotifyArtistName(details.name);
      setIsModalExpanded(false);
      setShowWelcome(false);
      navigate(`/artist/${artistId}`, { replace: false });
    } catch (err) {
      console.error('Error in handleSearch:', err);
      setError('An error occurred while searching. Please try again.');
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  }, [musicBrainz, visualization, navigate]);

  const handleNodeClick = useCallback(async (node) => {
    if (node && node.id) {
      navigate(`/artist/${node.id}`, { replace: false });
      
      try {
        setIsLoading(true);
        const details = await musicBrainz.getArtistDetails(node.id);
        visualization.updateGraphData(details);
        setActiveNodeId(node.id);
        setActiveArtist(details);
        setSpotifyArtistName(details.name);
        setIsModalExpanded(false);
      } catch (err) {
        console.error('Error in handleNodeClick:', err);
        setError('An error occurred while fetching node details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [musicBrainz, visualization, navigate]);

  const handleMemberClick = useCallback(async (memberId, switchToArtistsView = true) => {
    if (memberId) {
      navigate(`/artist/${memberId}`, { replace: false });
      try {
        setIsLoading(true);
        const details = await musicBrainz.getArtistDetails(memberId);
        visualization.updateGraphData(details);
        setActiveNodeId(memberId);
        setActiveArtist(details);
        setSpotifyArtistName(details.name);
        if (switchToArtistsView) {
          setIsArtistsView(true);
        }
      } catch (err) {
        console.error('Error in handleMemberClick:', err);
        setError('An error occurred while fetching member details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [musicBrainz, visualization, navigate]);

  const toggleModalExpansion = () => {
    setIsModalExpanded(!isModalExpanded);
  };

  const filteredGraphData = useMemo(() => {
    const data = filterImmediateRelationships(visualization.graphData, activeNodeId);
    
    const sortedMembers = Object.values(data.nodes)
      .filter(node => node.id !== activeNodeId)
      .sort((a, b) => {
        // Sorting logic remains the same as in ArtistDetails
        const rolePriority = (member) => {
          const roles = member.roles || [];
          if (roles.some(role => role.toLowerCase().includes('lead vocals'))) return 1;
          if (roles.some(role => role.toLowerCase().includes('vocals'))) return 2;
          if (roles.some(role => role.toLowerCase().includes('guitar'))) return 3;
          return 4;
        };

        const priorityA = rolePriority(a);
        const priorityB = rolePriority(b);

        if (a.isOriginal !== b.isOriginal) {
          return a.isOriginal ? -1 : 1;
        }

        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }

        return 0; // If all else is equal, maintain original order
      });

    const nodesWithImportance = data.nodes.map((node) => {
      const sortIndex = node.id === activeNodeId ? -1 : sortedMembers.findIndex(member => member.id === node.id);
      console.log(`Node: ${node.name}, Type: ${node.details.type}, SortIndex: ${sortIndex}`);
      return {
        ...node,
        value: sortIndex === -1 ? sortedMembers.length + 1 : sortIndex + 1, // +1 to avoid zero-based index
      };
    });

    return {
      nodes: nodesWithImportance,
      links: data.links
    };
  }, [filterImmediateRelationships, visualization.graphData, activeNodeId]);

  const handleToggle = () => {
    setIsArtistsView(!isArtistsView);
  };

  // Add this function to prepare data for GrouplingsGraph
  const prepareGroupsData = useCallback(() => {
    if (!activeArtist || !activeArtist.relations) return { relatedGroups: [] };

    const members = activeArtist.relations.filter(rel => rel.type === 'member of band');
    const groupsWithMembers = members.reduce((acc, member) => {
      if (member.artist && member.artist.relations) {
        member.artist.relations
          .filter(rel => rel.type === 'member of band' && rel.artist && rel.artist.id !== activeArtist.id)
          .forEach(groupRel => {
            if (groupRel.artist && groupRel.artist.name) {
              if (!acc[groupRel.artist.id]) {
                acc[groupRel.artist.id] = {
                  id: groupRel.artist.id,
                  name: groupRel.artist.name,
                  members: new Set()
                };
              }
              acc[groupRel.artist.id].members.add(member.artist.id);
            }
          });
      }
      return acc;
    }, {});

    const sortedGroups = Object.values(groupsWithMembers)
      .map(group => ({
        ...group,
        count: group.members.size,
        members: Array.from(group.members)
      }))
      .sort((a, b) => b.count - a.count);

    return { relatedGroups: sortedGroups };
  }, [activeArtist]);

  return (
    <div className="visualization-page">
      <div className="visualization-content">
        {isLoading ? (
          <div className="loading-message">
            <img src={process.env.PUBLIC_URL + '/favicon.png'} alt="Loading" className="loading-spinner" />
            <p>Cooking something up (this could take a while)...</p>
          </div>
        ) : (
          <>
            <div className="visualization-header">
              <div className="active-artist-header">
                {error ? (
                  <span className="active-artist-name" style={{ color: '#666' }}>Artist Not Found</span>
                ) : activeArtist ? (
                  <span className="active-artist-name">{activeArtist?.name}</span>
                ) : null}
                <SearchBar onSearch={handleSearch} activeArtist={activeArtist} />
              </div>
            </div>
            
            {error && showErrorModal && (
              <div className="error-modal-overlay">
                <div className="error-modal">
                  <p>{error}</p>
                  <button onClick={() => setShowErrorModal(false)}>OK</button>
                </div>
              </div>
            )}
            
            {!showWelcome && containerSize.width > 0 && containerSize.height > 0 && activeArtist && (
              <GraphToggle 
                isArtistsView={isArtistsView} 
                onToggle={handleToggle} 
                activeArtist={activeArtist}
              />
            )}
          </>
        )}
        
        <div 
          ref={containerRef}
          className="visualization-container" 
        >
          {!showWelcome && containerSize.width > 0 && containerSize.height > 0 && (
            isArtistsView ? (
              <Graph 
                data={filteredGraphData} 
                onNodeClick={handleNodeClick}
                width={containerSize.width}
                height={containerSize.height}
                isMobile={isMobile}
                activeNodeId={activeNodeId}
              />
            ) : (
              <div className="grouplings-container">
                <GrouplingsGraph
                  data={prepareGroupsData()}
                  onNodeClick={handleNodeClick}
                  isMobile={isMobile}
                />
              </div>
            )
          )}
        </div>
      </div>
      {activeArtist && (
        <ArtistDetails
          artist={activeArtist}
          spotifyArtistName={spotifyArtistName}
          isExpanded={isModalExpanded}
          onToggleExpand={toggleModalExpansion}
          isMobile={isMobile}
          onMemberClick={handleMemberClick}
          isArtistsView={isArtistsView}
          onToggleView={setIsArtistsView}
        />
      )}
    </div>
  );
};

export default Visualization;
