import React, { useState, useEffect, useRef } from 'react';
import { useMusicBrainz } from '../../hooks/useMusicBrainz';

function SearchBar({ onSearch, activeArtist }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const { searchArtistLight } = useMusicBrainz();
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        if (isExpanded && !searchTerm && activeArtist) {
          setIsExpanded(false);
        }
        setShowSuggestions(false);
      }
    };

    const handleEscKey = (event) => {
      if (event.key === 'Escape' && isExpanded && activeArtist) {
        setIsExpanded(false);
        setSearchTerm('');
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscKey);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isExpanded, searchTerm, activeArtist]);

  useEffect(() => {
    if (activeArtist) {
      setIsExpanded(false);
      setSearchTerm('');
      setSuggestions([]);
      setShowSuggestions(false);
    } else {
      setIsExpanded(true);
    }
  }, [activeArtist]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm.length >= 3) {
        try {
          const results = await searchArtistLight(searchTerm, 4);
          setSuggestions(results || []);
          setShowSuggestions(true);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          setSuggestions([]);
          setShowSuggestions(true);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, searchArtistLight]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSuggestionClick = (suggestion) => {
    onSearch(suggestion.id);
    setSearchTerm('');
    setShowSuggestions(false);
    setSuggestions([]);
  };

  const handleInputFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  return (
    <div className="search-bar-container" ref={searchRef}>
      {isExpanded ? (
        <>
          <form className="search-bar" onSubmit={handleSubmit}>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={handleInputFocus}
              placeholder="Search artist or group"
              className="search-input"
              autoFocus
            />
            <button 
              type="button" 
              disabled={true} 
              className={`search-button ${showSuggestions && suggestions.length > 0 ? 'has-results' : ''}`}
            >
              <img src="/images/search.png" alt="Search" width="32" height="32" />
            </button>
          </form>
          {showSuggestions && (
            <ul className="search-suggestions">
              {suggestions.length > 0 ? (
                <>
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.id}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion.name}
                      {suggestion.disambiguation && (
                        <span className="disambiguation">
                          {' '}({suggestion.disambiguation})
                        </span>
                      )}
                    </li>
                  ))}
                  <li className="search-helper-text" style={{ pointerEvents: 'none' }}>
                    Select an artist from above
                  </li>
                </>
              ) : (
                searchTerm.length >= 3 && (
                  <li className="search-helper-text" style={{ pointerEvents: 'none' }}>
                    No matches found
                  </li>
                )
              )}
            </ul>
          )}
        </>
      ) : (
        <div className="search-bar-collapsed" onClick={() => setIsExpanded(true)}>
          <button className="search-button">
            <img src="/images/search.png" alt="Search" width="32" height="32" />
          </button>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
