import { useState, useCallback } from 'react';

export const useVisualization = () => {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });

  const cleanAttribute = (attr) => {
    return attr.replace(/\s*\([^)]*\)/g, '').replace(/\boriginal\b/i, '').trim();
  };

  const updateGraphData = useCallback((artist) => {
    if (!artist || !artist.id) {
      console.error('Invalid artist data');
      return;
    }

    setGraphData(prevData => {
      const newNodes = [
        { 
          id: artist.id, 
          name: artist.name, 
          type: artist.type, // Ensure we're passing the type
          group: artist.type === 'Group' ? 'Group' : 'Person', 
          details: artist 
        }
      ];
      const newLinks = [];

      const relationships = artist.relations || [];
      relationships.forEach(rel => {
        if (rel.type === 'member of band' || rel.type === 'member') {
          const targetId = rel.target?.id || rel.artist?.id;
          const targetName = rel.artist?.name || rel.target?.name || 'Unknown';
          if (targetId) {
            const attributes = rel.attributes || [];
            const cleanedAttributes = attributes.map(cleanAttribute).filter(Boolean);
            
            // Find existing node or create a new one
            let node = newNodes.find(n => n.id === targetId);
            if (!node) {
              node = { 
                id: targetId, 
                name: targetName, 
                group: rel.type === 'member of band' ? 'Group' : 'Person',
                roles: [],
                details: rel.artist || rel.target
              };
              newNodes.push(node);
            }

            // Add all cleaned attributes to the node's roles array
            node.roles = [...new Set([...node.roles, ...cleanedAttributes])];

            newLinks.push({ source: artist.id, target: targetId, type: rel.type });
          }
        }
      });

      // Remove duplicates and ensure consistent group assignment
      const uniqueNodes = [...new Map([...prevData.nodes, ...newNodes].map(node => [node.id, {
        ...node,
        group: node.group || (node.details?.type === 'Group' ? 'Group' : 'Person')
      }])).values()];
      const uniqueLinks = [...new Map([...prevData.links, ...newLinks].map(link => [`${link.source}-${link.target}`, link])).values()];

      return {
        nodes: uniqueNodes,
        links: uniqueLinks
      };
    });
  }, []);

  const resetGraphData = () => {
    setGraphData({ nodes: [], links: [] });
  };

  return { graphData, updateGraphData, resetGraphData };
};
