import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';

const Graph = ({ data, onNodeClick, width, height, isMobile, activeNodeId }) => {
  const graphRef = useRef();

  const baconColors = [
    '#8B4513', '#A0522D', '#CD853F', '#DEB887', '#D2691E', '#E27B36',
  ];

  const colorScale = d3.scaleOrdinal(baconColors);

  const memoizedData = useMemo(() => {
    const nodes = data.nodes.map((node, index) => ({
      ...node,
      color: colorScale(node.value),
    }));
    const links = data.links.map(link => ({
      ...link,
      value: 1
    }));

    return { nodes, links };
  }, [data, colorScale]);

  const handleNodeClick = useCallback((node) => {
    if (node && node.id) {
      onNodeClick(node);
    }
  }, [onNodeClick]);

  const nodeCanvasObject = useCallback((node, ctx, globalScale) => {
    const label = node.name;
    const fontSize = 12 / globalScale;
    ctx.font = `bold ${fontSize}px Sans-Serif`;
    const textWidth = ctx.measureText(label).width;

    const isActiveNode = node.id === activeNodeId;
    const maxCanvasSize = Math.min(width, height);
    const nodeCount = data.nodes.length;
    
    // Special sizing only for 3 or fewer nodes
    const maxNodeSize = nodeCount <= 3 
      ? Math.min(4, maxCanvasSize * 0.01)  // Very small for few nodes
      : Math.min(15, maxCanvasSize * 0.04); // Normal size for more nodes
    
    const minNodeSize = nodeCount <= 3 ? 3 : 6;  // Smaller minimum for few nodes

    const nodeSize = isActiveNode 
      ? nodeCount <= 3 
        ? Math.min(maxNodeSize * 1.2, 5)  // Smaller active node for few nodes
        : Math.min(maxNodeSize * 1.2, 15)  // Normal active node size
      : maxNodeSize - ((node.value - 1) / (nodeCount - 1)) * (maxNodeSize - minNodeSize);

    const boundedX = Math.max(nodeSize, Math.min(width - nodeSize, node.x));
    const boundedY = Math.max(nodeSize, Math.min(height - nodeSize, node.y));
    node.x = boundedX;
    node.y = boundedY;

    // Store node size for pointer area
    node.__bckgDimensions = [nodeSize * 2, nodeSize * 2];  // Store diameter for click detection
    node.__bckgPos = { x: boundedX, y: boundedY };  // Store position for click detection

    ctx.fillStyle = isActiveNode ? '#8B4513' : node.color;
    ctx.globalAlpha = 0.7;
    ctx.beginPath();
    ctx.arc(boundedX, boundedY, nodeSize, 0, 2 * Math.PI, false);
    ctx.fill();

    if (isActiveNode) {
      ctx.globalAlpha = 1;
      ctx.strokeStyle = '#8B4513';
      ctx.lineWidth = 2;
      ctx.stroke();

      const time = Date.now() * 0.003;
      const haloSize = nodeSize + 5 + Math.sin(time) * 3;
      ctx.globalAlpha = 0.2;
      ctx.beginPath();
      ctx.arc(boundedX, boundedY, haloSize, 0, 2 * Math.PI, false);
      ctx.strokeStyle = '#8B4513';
      ctx.lineWidth = 1.5;
      ctx.stroke();
    }

    ctx.globalAlpha = 1;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';
    ctx.fillText(label, node.x, node.y);

    if (node.roles && node.roles.length > 0) {
      const rolesText = node.roles.join(', ');
      const roleFontSize = Math.max(8, fontSize * 0.7) / globalScale;
      ctx.font = `${roleFontSize}px Sans-Serif`;
      ctx.fillStyle = '#666';
      
      const maxWidth = nodeSize * 4;
      const words = rolesText.split(' ');
      let line = '';
      let lineHeight = roleFontSize * 1.2;
      let y = node.y + nodeSize + lineHeight;

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, node.x, y);
          line = words[n] + ' ';
          y += lineHeight;
        } else {
          line = testLine;
        }
      }
      ctx.fillText(line, node.x, y);
    }

    node.__bckgDimensions = [textWidth, fontSize];
  }, [activeNodeId, data.nodes, width, height, isMobile]);

  const nodePointerAreaPaint = useCallback((node, color, ctx) => {
    // Draw circular pointer area
    if (node.__bckgDimensions && node.__bckgPos) {
      ctx.beginPath();
      ctx.arc(node.__bckgPos.x, node.__bckgPos.y, node.__bckgDimensions[0] / 2, 0, 2 * Math.PI);
      ctx.fillStyle = color;
      ctx.fill();
    }
  }, []);

  const fitGraphToCanvas = useCallback(() => {
    if (graphRef.current) {
      const fg = graphRef.current;
      fg.zoomToFit(400, 50);
    }
  }, []);

  useEffect(() => {
    if (graphRef.current) {
      const fg = graphRef.current;
      const nodeCount = data.nodes.length;
      
      // Adjust forces based on number of nodes
      if (nodeCount <= 3) {
        fg.d3Force('charge').strength(-50);  // Reduced repulsion for few nodes
        fg.d3Force('link').distance(20);     // Shorter links for few nodes
        fg.d3Force('center', d3.forceCenter(width / 2, height / 2).strength(0.3));  // Stronger centering
        fg.d3Force('collide', d3.forceCollide(node => 10 + node.value * 1));  // Smaller collision radius
      } else {
        fg.d3Force('charge').strength(-150);
        fg.d3Force('link').distance(30);
        fg.d3Force('center', d3.forceCenter(width / 2, height / 2));
        fg.d3Force('collide', d3.forceCollide(node => 15 + node.value * 2));
      }

      fitGraphToCanvas();
    }
  }, [width, height, fitGraphToCanvas, data.nodes.length]);

  return (
    <ForceGraph2D
      ref={graphRef}
      graphData={memoizedData}
      nodeLabel="name"
      nodeColor="color"
      linkColor={() => '#E6E6FA'}
      linkWidth={1}
      width={width}
      height={height}
      onNodeClick={handleNodeClick}
      nodeCanvasObject={nodeCanvasObject}
      nodePointerAreaPaint={nodePointerAreaPaint}
      cooldownTicks={50}
      linkDirectionalParticles={1}
      linkDirectionalParticleSpeed={d => d.value * 0.002}
      nodeRelSize={1}
      enableZoomInteraction={true}
      enablePanInteraction={true}
      enableNodeDrag={true}
      onEngineStop={fitGraphToCanvas}
    />
  );
};

export default React.memo(Graph);
