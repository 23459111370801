import React, { useContext } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { VisualizationContext } from '../../context/VisualizationContext';
import TutorialButton from './TutorialButton';

const Layout = ({ onShowTutorial }) => {
  const navigate = useNavigate();
  const visualization = useContext(VisualizationContext);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (visualization && visualization.resetGraphData) {
      visualization.resetGraphData();
    }
    navigate('/', { replace: true });
  };

  return (
    <div className="layout">
      <header className={`header ${isHomePage ? 'home-header' : ''}`}>
        <div className="header-content">
          <a href="/" onClick={handleLogoClick}>
            <img src="/images/logo.png" alt="Band Bacon" className="logo-image" />
          </a>
        </div>
      </header>
      <main className="main-content">
        <Outlet />
      </main>
      {location.pathname.startsWith('/artist/') && <TutorialButton onClick={onShowTutorial} />}
    </div>
  );
};

export default Layout;
