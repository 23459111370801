import React from 'react';
import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  if (!isHomePage) return null;

  return (
    <div className="home-footer">
      <div className="footer-content">
        <p>Data provided by <a href="https://musicbrainz.org/" target="_blank" rel="noopener noreferrer">MusicBrainz</a></p>
        <p>A creative experiment by <a href="https://joshuamanning.com" target="_blank" rel="noopener noreferrer">Josh Manning</a></p>
      </div>
    </div>
  );
}

export default Footer;
