import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Visualization from './pages/Visualization';
import About from './pages/About';
import Home from './pages/Home';
import { FirebaseProvider } from './context/FirebaseContext';
import { VisualizationProvider } from './context/VisualizationContext';
import TutorialModal from './components/modals/TutorialModal';

function App() {
  const [showTutorial, setShowTutorial] = useState(false);

  const handleShowTutorial = () => {
    console.log('Opening tutorial');
    setShowTutorial(true);
  };

  const handleCloseTutorial = () => {
    console.log('Closing tutorial from App');
    setShowTutorial(false);
  };

  useEffect(() => {
    console.log('Tutorial state changed:', showTutorial);
  }, [showTutorial]);

  return (
    <>
      <FirebaseProvider>
        <VisualizationProvider>
          <Router>
            <Routes>
              <Route element={<Layout onShowTutorial={handleShowTutorial} />}>
                <Route path="/" element={<Home />} />
                <Route 
                  path="/artist/:artistId" 
                  element={
                    <Visualization 
                      onVisualizationLoad={handleShowTutorial}
                      showTutorial={showTutorial}
                    />
                  } 
                />
                <Route path="/about" element={<About />} />
                <Route path="/visualization" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </Router>
        </VisualizationProvider>
      </FirebaseProvider>
      
      <TutorialModal 
        isOpen={showTutorial} 
        onClose={handleCloseTutorial}
      />
    </>
  );
}

export default App;
