import React from 'react';
import { createPortal } from 'react-dom';

const TutorialModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleTemporaryClose = (e) => {
    console.log('Temporary close clicked');
    if (e) e.preventDefault();
    if (onClose) {
      console.log('Calling onClose');
      onClose();
    } else {
      console.log('onClose is not defined');
    }
  };

  const handlePermanentClose = (e) => {
    console.log('Permanent close clicked');
    if (e) e.preventDefault();
    localStorage.setItem('hasSeenVisualizationTutorial', 'true');
    if (onClose) {
      console.log('Calling onClose');
      onClose();
    } else {
      console.log('onClose is not defined');
    }
  };

  const handleOverlayClick = (e) => {
    console.log('Overlay clicked');
    if (e.target === e.currentTarget) {
      handleTemporaryClose(e);
    }
  };

  return createPortal(
    <div className="tutorial-modal-overlay" onClick={handleOverlayClick}>
      <div className="tutorial-modal" onClick={e => e.stopPropagation()}>
        <button 
          onClick={handlePermanentClose}
          className="tutorial-modal-close-button"
          aria-label="Close modal"
        >
          ✕
        </button>

        <div className="tutorial-modal-header">
          <h2 className="tutorial-modal-title">
            <img 
              src={process.env.PUBLIC_URL + '/favicon.png'} 
              alt="Band Bacon" 
              style={{ width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle' }} 
            />
            Ready to rock?
          </h2>
          <p className="tutorial-modal-subtitle">First, let's do a quick rehearsal and make sure we're set.</p>
        </div>

        <div className="tutorial-modal-body">
          <div className="tutorial-modal-section">
            <h3 className="tutorial-modal-section-title">Search the Collection</h3>
            <div className="tutorial-modal-example-container">
              <div className="tutorial-modal-search-row">
                <div className="tutorial-modal-search-example">
                  <img 
                    src="/images/search.png" 
                    alt="Search" 
                    className="tutorial-modal-search-icon"
                  />
                </div>
                <span className="tutorial-modal-text">
                  Click to continue searching other artists.
                </span>
              </div>
            </div>
          </div>

          <div className="tutorial-modal-section">
            <h3 className="tutorial-modal-section-title">View the Collection</h3>
            <p className="tutorial-modal-text" style={{marginTop: '8px', textAlign: 'center'}}>
              Tap and drag the graph • Pinch to zoom • Pan around
            </p>
            <div className="tutorial-modal-example-container">
              <div className="tutorial-modal-toggle-example">
                <div className="tutorial-modal-toggle-option" style={{backgroundColor: 'white', color: '#8B4513'}}>
                  <img 
                    src={process.env.PUBLIC_URL + '/images/artists.svg'} 
                    alt="Artists" 
                    className="tutorial-modal-toggle-icon"
                  /> 
                  Relationships
                </div>
                <div className="tutorial-modal-toggle-option" style={{color: 'white'}}>
                  <img 
                    src={process.env.PUBLIC_URL + '/images/group.svg'} 
                    alt="Groups" 
                    className="tutorial-modal-toggle-icon"
                  /> 
                  Related Groups
                </div>
              </div>
              <p className="tutorial-modal-text">
                Use the toggle to switch between views
              </p>
              <div className="tutorial-modal-views-container">
                <div className="tutorial-modal-view-box">
                  <p className="tutorial-modal-text">
                    <strong>Relationships</strong> - Click on the graph nodes to explore connections between related groups and artists.
                  </p>
                </div>
                <div className="tutorial-modal-view-box">
                  <p className="tutorial-modal-text">
                    <strong>Related Groups</strong> - Discover supergroups and side projects. Bigger bubbles mean bigger collabs.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="tutorial-modal-section">
            <h3 className="tutorial-modal-section-title">Go Backstage</h3>
            <p className="tutorial-modal-text">
              Hit "More Details" below for the VIP experience - complete with Spotify integration (when available), full lineup history, and all their greatest hits and deep cuts.
            </p>
          </div>
        </div>

        <div className="tutorial-modal-footer">
          <button
            onClick={handleTemporaryClose}
            className="tutorial-modal-button-secondary"
          >
            Skip the sound check for now
          </button>
          <button
            onClick={handlePermanentClose}
            className="tutorial-modal-button-primary"
          >
            Let's Go! 🤘
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default TutorialModal;