import React, { createContext, useState } from 'react';

export const VisualizationContext = createContext();

export const VisualizationProvider = ({ children }) => {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });

  const value = {
    graphData,
    setGraphData,
  };

  return (
    <VisualizationContext.Provider value={value}>
      {children}
    </VisualizationContext.Provider>
  );
};
