import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useSpotify } from '../hooks/useSpotify';
import { useMusicBrainz } from '../hooks/useMusicBrainz';
import { useNavigate } from 'react-router-dom';

const ArtistDetails = ({ artist, spotifyArtistName, isExpanded, onToggleExpand, isMobile, onMemberClick, isArtistsView, onToggleView }) => {
  const { spotifyId, error, isCloseMatch, refetch } = useSpotify(spotifyArtistName);
  
  // Add these console logs
  console.log('ArtistDetails rendered for:', artist.name);
  console.log('Spotify artist name:', spotifyArtistName);
  console.log('Spotify ID:', spotifyId);
  console.log('Spotify error:', error);

  const [showInfoBox, setShowInfoBox] = useState(true);
  const [genres, setGenres] = useState([]);
  const [isLoadingSpotify, setIsLoadingSpotify] = useState(true);
  const [showRetry, setShowRetry] = useState(false);
  const [key, setKey] = useState(0);
  const navigate = useNavigate();

  const { getArtistDetails } = useMusicBrainz();

  // Add this useEffect for logging
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (error) {
        console.error('Spotify data loading error:', error);
      } else if (!spotifyId) {
        console.log('No Spotify data found for artist:', artist.name);
      }
    }
  }, [spotifyId, error, artist.name]);

  const handleRetry = useCallback(() => {
    setIsLoadingSpotify(true);
    setShowRetry(false);
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fetchArtistDetails = async () => {
      if (artist.id) {
        try {
          const details = await getArtistDetails(artist.id);
          if (details && details.genres) {
            setGenres(details.genres);
          } else {
            console.error('No genres found in artist details');
            setGenres([]);
          }
        } catch (error) {
          console.error('Error fetching artist details:', error);
          setGenres([]);
        }
        setIsLoadingSpotify(true);
        setShowInfoBox(true);
        setShowRetry(false);
        setKey(prevKey => prevKey + 1); // Force re-render
      }
    };

    fetchArtistDetails();
  }, [artist.id, getArtistDetails]);

  useEffect(() => {
    setKey(prevKey => prevKey + 1); // Force re-render when expanded/contracted
  }, [isExpanded]);

  // New useEffect to handle Spotify loading state
  useEffect(() => {
    let timeoutId;
    if (spotifyId !== undefined || error) {
      timeoutId = setTimeout(() => {
        setIsLoadingSpotify(false);
      }, 1000);
    } else {
      setIsLoadingSpotify(true);
      timeoutId = setTimeout(() => {
        setShowRetry(true);
      }, 10000);
    }

    return () => clearTimeout(timeoutId);
  }, [spotifyId, error, spotifyArtistName]);

  const formatDate = (begin, end) => {
    const formatYear = (date) => date ? new Date(date).getFullYear() : null;
    const beginYear = formatYear(begin);
    const endYear = formatYear(end);

    if (beginYear && endYear) {
      return `${beginYear} - ${endYear}`;
    } else if (beginYear) {
      return `${beginYear} - present`;
    } else if (endYear) {
      return `? - ${endYear}`;
    }
    return '';
  };

  const groupedRelations = useMemo(() => {
    if (!artist || !artist.relations) return {};

    const memberRelations = artist.relations.filter(rel => 
      rel.type === 'member of band' || rel.type === 'member'
    );

    return memberRelations.reduce((acc, rel) => {
      const name = rel.artist?.name || rel.target?.name || 'Unknown';
      const id = rel.artist?.id || rel.target?.id;
      if (!acc[id]) {
        acc[id] = { name, id, roles: {}, isOriginal: false, mostRecentYear: 0 };
      }
      
      const roles = (rel.attributes || []).filter(attr => attr !== 'original').map(attr => attr.split(' (')[0]);
      const dateRange = formatDate(rel.begin, rel.end);
      const endYear = rel.end ? new Date(rel.end).getFullYear() : new Date().getFullYear();
      
      if (!acc[id].roles[dateRange]) {
        acc[id].roles[dateRange] = [];
      }
      acc[id].roles[dateRange].push(...roles);
      
      if ((rel.attributes || []).includes('original')) {
        acc[id].isOriginal = true;
      }
      
      acc[id].mostRecentYear = Math.max(acc[id].mostRecentYear, endYear);
      
      return acc;
    }, {});
  }, [artist]);

  const sortedMembers = useMemo(() => {
    return Object.values(groupedRelations).sort((a, b) => {
      const rolePriority = (member) => {
        const roles = Object.values(member.roles).flat();
        if (roles.some(role => role.toLowerCase().includes('lead vocals'))) return 1;
        if (roles.some(role => role.toLowerCase().includes('vocals'))) return 2;
        if (roles.some(role => role.toLowerCase().includes('guitar'))) return 3;
        return 4;
      };

      const priorityA = rolePriority(a);
      const priorityB = rolePriority(b);

      if (a.isOriginal !== b.isOriginal) {
        return a.isOriginal ? -1 : 1;
      }

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }

      return b.mostRecentYear - a.mostRecentYear;
    });
  }, [groupedRelations]);

  const handleMemberClick = useCallback((memberId) => {
    // If we're in Related Groups view and clicking a group, stay in Related Groups view
    if (!isArtistsView && artist.type === 'Group') {
      onMemberClick(memberId, false);  // Pass false to keep Related Groups view
    } else {
      onMemberClick(memberId, true);   // Otherwise switch to Relationships view
    }
  }, [onMemberClick, isArtistsView, artist.type]);

  const handleViewRelatedGroups = useCallback(() => {
    navigate(`/group-lookup/artist/${artist.id}`);
  }, [navigate, artist.id]);

  const renderTabs = () => {
    if (artist.type !== 'Group') return null;

    return (
      <div className="artist-tabs">
        <button 
          className={`tab-button ${isArtistsView ? 'active' : ''}`}
          onClick={() => onToggleView(true)}
        >
          Members
        </button>
        <button 
          className={`tab-button ${!isArtistsView ? 'active' : ''}`}
          onClick={() => onToggleView(false)}
        >
          Related Groups
        </button>
      </div>
    );
  };

  const renderTabContent = () => {
    if (artist.type !== 'Group') {
      return renderRelationships();
    }

    return isArtistsView ? renderRelationships() : renderRelatedGroups();
  };

  if (!artist) return null;

  const renderMetadata = () => (
    <div className="artist-metadata">
      <p>
        <strong>Life span:</strong> <span className="metadata-value">{formatDate(artist['life-span']?.begin, artist['life-span']?.end)}</span>
      </p>
      <p><strong>Country:</strong> <span className="metadata-value">{artist.country || 'Unknown'}</span></p>
    </div>
  );

  const renderDisambiguation = () => (
    <p className="disambiguation">
      <strong>{artist.name}</strong>
      {artist.disambiguation && (
        <span>{' '}({artist.disambiguation})</span>
      )}
    </p>
  );

  const renderSpotifyPlayer = () => {
    if (isLoadingSpotify) {
      return (
        <div className="spotify-loading">
        <span className="loading-text">
          Loading Spotify data
        </span>
        {showRetry && (
          <button onClick={handleRetry} style={{
            marginLeft: '10px',
            padding: '5px 10px',
            backgroundColor: '#1DB954',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}>
            Retry
          </button>
        )}
      </div>
      );
    }

    if (spotifyId && isCloseMatch) {
      return (
        <iframe
          src={`https://open.spotify.com/embed/artist/${spotifyId}`}
          width="100%"
          height="80"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
          title="Spotify Player"
        ></iframe>
      );
    } else if (showInfoBox) {
      return (
        <div className="info-box" style={{
          backgroundColor: '#f9f9f9',
          padding: '15px',
          borderRadius: '8px',
          marginBottom: '15px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
          <p style={{ margin: 0, flex: '1 1 auto' }}>
            Can't find this artist on{' '}
            <a 
              href="https://www.spotify.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: '#1DB954', textDecoration: 'none', fontWeight: 'bold' }}
            >
              Spotify
            </a>
          </p>
          <button onClick={() => setShowInfoBox(false)} style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            color: '#007bff',
            fontWeight: 'bold',
            flex: '0 0 auto',
            marginLeft: '10px'
          }}>Dismiss</button>
        </div>
      );
    }
    return null;
  };

  const renderGenres = () => {
    if (genres.length > 0) {
      return (
        <div className="artist-genres" style={{ color: '#333', fontSize: '0.9rem' }}>
          <p><strong style={{ color: '#333' }}>Genre(s):</strong> {genres.map(genre => genre.name).join(', ')}</p>
        </div>
      );
    }
    return null;
  };

  const renderRelationships = () => {
    if (sortedMembers.length === 0) return null;

    return (
      <div className="artist-relationships">
        <ul>
          {sortedMembers.map((member) => (
            <li key={member.id} className="member-item">
              <span className="member-name" onClick={() => handleMemberClick(member.id)}>
                {member.name}
              </span>
              {member.isOriginal && <span className="original-label">Original</span>}
              {Object.entries(member.roles).map(([dateRange, roles], index) => (
                <div key={index} className="role-info">
                  <span className="roles">{roles.join(', ')}</span>
                  <span className="date-range">{dateRange}</span>
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderRelatedGroups = () => {
    if (!artist || !artist.relations) return null;

    const members = artist.relations.filter(rel => rel.type === 'member of band');
    const groupsWithMembers = members.reduce((acc, member) => {
      if (member.artist && member.artist.relations) {
        member.artist.relations
          .filter(rel => rel.type === 'member of band' && rel.artist && rel.artist.id !== artist.id)
          .forEach(groupRel => {
            if (groupRel.artist && groupRel.artist.id) {
              if (!acc[groupRel.artist.id]) {
                acc[groupRel.artist.id] = { 
                  name: groupRel.artist.name, 
                  id: groupRel.artist.id,
                  members: new Map()
                };
              }
              const memberKey = `${member.artist.id}-${groupRel.artist.id}`;
              acc[groupRel.artist.id].members.set(memberKey, {
                id: member.artist.id,
                name: member.artist.name
              });
            }
          });
      }
      return acc;
    }, {});

    const relatedGroups = Object.values(groupsWithMembers)
      .map(group => ({ 
        ...group, 
        count: group.members.size, 
        members: Array.from(group.members.values())
      }))
      .sort((a, b) => b.count - a.count);

    return (
      <div className="artist-relationships">
        <ul>
          {relatedGroups.map((group) => (
            <li key={group.id} className="member-item">
              <span 
                className="member-name" 
                onClick={() => handleMemberClick(group.id)}
              >
                {group.name}
              </span>
              <span className="shared-members-count">
                ({group.count} shared member{group.count !== 1 ? 's' : ''})
              </span>
              <div className="shared-members-list">
                {group.members.map((member) => (
                  <span 
                    key={`${group.id}-${member.id}`}
                    className="shared-member-link"
                    onClick={() => handleMemberClick(member.id)}
                  >
                    {member.name}
                  </span>
                ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className={`artist-details-modal ${isExpanded ? 'expanded' : 'contracted'} ${isMobile ? 'mobile' : ''}`}>
      <div className="modal-header" onClick={onToggleExpand}>
        <h2>
          More Details 
          <span className="expand-text">
            {isExpanded ? ' (click to collapse)' : ' (click to expand)'}
          </span>
        </h2>
        <div className="expand-indicator"></div>
      </div>
      <div className="modal-content">
        {renderDisambiguation()}
        {renderSpotifyPlayer()}
        {renderGenres()}
        {renderMetadata()}
        {renderTabs()}
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ArtistDetails;
