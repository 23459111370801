import React from 'react';

const GraphToggle = ({ isArtistsView, onToggle, activeArtist }) => {
  const isDisabled = activeArtist?.type !== 'Group';

  return (
    <div 
      className={`graph-toggle-container ${isDisabled ? 'disabled' : ''}`} 
      title={isDisabled ? "Related groups view is only available for bands/groups" : ""}
    >
      <label className="graph-toggle-switch">
        <input
          type="checkbox"
          checked={!isArtistsView}
          onChange={onToggle}
          disabled={isDisabled}
        />
        <div className="graph-toggle-slider">
          <div className="graph-toggle-labels">
            <span className={isArtistsView ? 'inactive' : 'active'}>
              <img src={process.env.PUBLIC_URL + '/images/artists.svg'} alt="Artists" className="toggle-icon" /> Relationships
            </span>
            <span className={`${!isArtistsView ? 'inactive' : 'active'} ${isDisabled ? 'disabled-option' : ''}`}>
              <img src={process.env.PUBLIC_URL + '/images/group.svg'} alt="Related Groups" className="toggle-icon" /> Related Groups
            </span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default GraphToggle;
