import { useState, useEffect } from 'react';

const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const TOKEN_URL = 'https://accounts.spotify.com/api/token';
const SEARCH_URL = 'https://api.spotify.com/v1/search';
const TOP_TRACKS_URL = 'https://api.spotify.com/v1/artists';

export const useSpotify = (artistName, artistCountry, artistGenres) => {
  const [spotifyId, setSpotifyId] = useState(null);
  const [topTracks, setTopTracks] = useState([]);
  const [error, setError] = useState(null);
  const [isCloseMatch, setIsCloseMatch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const response = await fetch(TOKEN_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + btoa(CLIENT_ID + ':' + CLIENT_SECRET)
          },
          body: new URLSearchParams({
            'grant_type': 'client_credentials',
          })
        });

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || 'Failed to fetch access token');
        }
        return data.access_token;
      } catch (err) {
        setError('Failed to fetch access token');
        console.error(err);
      }
    };

    const fetchSpotifyId = async () => {
      setIsLoading(true);
      setError(null);
      const token = await fetchAccessToken();
      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${SEARCH_URL}?q=${encodeURIComponent(artistName)}&type=artist&limit=5`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();
        const potentialMatches = data.artists.items;

        // Find the best match using additional metadata
        const bestMatch = potentialMatches.find(artist => {
          const similarity = calculateSimilarity(artistName, artist.name);
          const countryMatch = artistCountry ? artist.country === artistCountry : true;
          const genreMatch = artistGenres ? artistGenres.some(genre => artist.genres.includes(genre)) : true;
          return similarity > 0.7 && countryMatch && genreMatch;
        });

        if (bestMatch) {
          setSpotifyId(bestMatch.id);
          setIsCloseMatch(true);
          fetchTopTracks(bestMatch.id, token);
        } else {
          setIsCloseMatch(false);
          setError('Artist not found on Spotify');
        }
      } catch (err) {
        setError('Failed to fetch Spotify ID');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTopTracks = async (id, token) => {
      try {
        const response = await fetch(`${TOP_TRACKS_URL}/${id}/top-tracks?market=US`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();
        setTopTracks(data.tracks || []);
      } catch (err) {
        setError('Failed to fetch top tracks');
        console.error(err);
      }
    };

    const calculateSimilarity = (a, b) => {
      const cleanString = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
      return cleanString(a) === cleanString(b) ? 1 : 0;
    };

    if (artistName) {
      fetchSpotifyId();
    } else {
      setSpotifyId(null);
      setTopTracks([]);
      setError(null);
      setIsCloseMatch(true);
      setIsLoading(false);
    }
  }, [artistName, artistCountry, artistGenres]);

  return { spotifyId, topTracks, error, isCloseMatch, isLoading };
};
