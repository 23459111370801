import { useCallback, useRef, useEffect } from 'react';

const API_BASE = 'https://musicbrainz.org/ws/2';
const RATE_LIMIT = 1000; // 1 second between requests
const MAX_RETRIES = 3;
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export function useMusicBrainz() {
  const lastRequestTime = useRef(0);
  const requestQueue = useRef([]);
  const cache = useRef({});

  useEffect(() => {
    // Load cache from localStorage on mount
    const storedCache = localStorage.getItem('artistCache');
    if (storedCache) {
      cache.current = JSON.parse(storedCache);
    }
  }, []);

  const saveCache = useCallback(() => {
    localStorage.setItem('artistCache', JSON.stringify(cache.current));
  }, []);

  const getCachedData = useCallback((key) => {
    const cachedItem = cache.current[key];
    if (cachedItem && Date.now() - cachedItem.timestamp < CACHE_EXPIRY) {
      return cachedItem.data;
    }
    return null;
  }, []);

  const setCachedData = useCallback((key, data) => {
    cache.current[key] = { data, timestamp: Date.now() };
    saveCache();
  }, [saveCache]);

  const throttledFetch = useCallback(async (url, retries = 0) => {
    return new Promise((resolve, reject) => {
      requestQueue.current.push({ url, resolve, reject, retries });
      processQueue();
    });
  }, []);

  const processQueue = useCallback(async () => {
    if (requestQueue.current.length === 0) return;

    const now = Date.now();
    const timeToWait = Math.max(0, RATE_LIMIT - (now - lastRequestTime.current));

    setTimeout(async () => {
      if (requestQueue.current.length === 0) return;

      const { url, resolve, reject, retries } = requestQueue.current.shift();
      lastRequestTime.current = Date.now();

      try {
        const response = await fetch(url, {
          headers: {
            'User-Agent': 'BandBacon/1.0.0 ( https://bandbacon.com )'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        resolve(data);
      } catch (error) {
        if (retries < MAX_RETRIES) {
          console.log(`Retrying request (${retries + 1}/${MAX_RETRIES})`);
          requestQueue.current.push({ url, resolve, reject, retries: retries + 1 });
        } else {
          reject(error);
        }
      }

      processQueue();
    }, timeToWait);
  }, []);

  const searchArtist = useCallback(async (term, type = null) => {
    const query = `artist:${term}${type ? ` AND type:${type}` : ''}`;
    const response = await throttledFetch(`${API_BASE}/artist?query=${encodeURIComponent(query)}&fmt=json`);
    return response.artists || [];
  }, [throttledFetch]);

  const searchArtistLight = useCallback(async (term, limit = 5) => {
    try {
      console.log('Searching for artist (light):', term);
      const searchData = await throttledFetch(`${API_BASE}/artist?query=${encodeURIComponent(term)}&fmt=json&limit=${limit}`);
      if (searchData.artists && searchData.artists.length > 0) {
        return searchData.artists.map(artist => ({
          id: artist.id,
          name: artist.name,
          disambiguation: artist.disambiguation || '',
          type: artist.type // Include the artist type
        }));
      }
      return [];
    } catch (error) {
      console.error('Error in searchArtistLight:', error);
      return [];
    }
  }, [throttledFetch]);

  const getArtistDetails = useCallback(async (artistId) => {
    const cacheKey = `artist:${artistId}`;
    const cachedData = getCachedData(cacheKey);
    if (cachedData) {
      console.log('Returning cached data for artist ID:', artistId);
      return cachedData;
    }

    try {
      console.log('Fetching details for artist ID:', artistId);
      const details = await throttledFetch(`${API_BASE}/artist/${artistId}?inc=artist-rels+url-rels+aliases+genres+recordings+releases+release-groups&fmt=json`);
      
      if (details.type === 'Group') {
        console.log('Fetching member details for group:', details.name);
        const memberRelations = details.relations.filter(rel => 
          rel.type === 'member of band' || rel.type === 'member'
        );
        
        const uniqueMembers = new Map();
        for (const rel of memberRelations) {
          const memberId = rel.artist.id;
          if (!uniqueMembers.has(memberId)) {
            console.log('Fetching details for member:', rel.artist.name);
            const memberDetails = await throttledFetch(`${API_BASE}/artist/${memberId}?inc=artist-rels&fmt=json`);
            uniqueMembers.set(memberId, { ...rel, artist: memberDetails });
          } else {
            console.log('Using existing details for member:', rel.artist.name);
            const existingMember = uniqueMembers.get(memberId);
            existingMember.attributes = [...new Set([...existingMember.attributes, ...rel.attributes])];
            if (rel.begin && (!existingMember.begin || new Date(rel.begin) < new Date(existingMember.begin))) {
              existingMember.begin = rel.begin;
            }
            if (rel.end && (!existingMember.end || new Date(rel.end) > new Date(existingMember.end))) {
              existingMember.end = rel.end;
            }
          }
        }
        details.relations = Array.from(uniqueMembers.values());
      }
      
      console.log('Caching data for artist ID:', artistId);
      setCachedData(cacheKey, details);
      return details;
    } catch (error) {
      console.error('Error fetching artist details:', error);
      return null;
    }
  }, [throttledFetch, getCachedData, setCachedData]);

  const searchArtistWithDetails = useCallback(async (term, type = null) => {
    const searchResults = await searchArtist(term, type);
    if (searchResults.length > 0) {
      return await getArtistDetails(searchResults[0].id);
    }
    return null;
  }, [searchArtist, getArtistDetails]);

  const clearCache = useCallback(() => {
    localStorage.removeItem('artistCache');
    cache.current = {};
    console.log('Cache cleared');
  }, []);

  return { 
    searchArtistWithDetails, 
    searchArtistLight,
    getArtistDetails,
    clearCache,
    searchArtist  // Add this to the returned object
  };
}
