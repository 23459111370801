import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../components/search/SearchBar';
import Footer from '../components/layout/Footer';

const Home = () => {
  const navigate = useNavigate();

  const handleSearch = (termOrId) => {
    if (termOrId) {
      navigate(`/artist/${termOrId}`);
    }
  };

  return (
    <div className="home-page">
      <div className="welcome-content">
        <div className="home-logo">
          <img src="/images/logo.png" alt="Band Bacon" className="logo-image" />
        </div>
        <h1>
          Discover the Six Degrees of <span className="highlight"><span className="strikethrough">Kevin</span> Band Bacon</span>
        </h1>
        <div className="home-search-section">
          <SearchBar onSearch={handleSearch} />
        </div>
        <p className="subtitle">Why let algorithms decide what to listen to when you can explore the direct connections between artists and uncover surprising musical relationships!</p>
      </div>
      <div className="home-footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
