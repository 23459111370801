import React from 'react';

function About() {
  return (
    <div>
      <h1>About Band Bacon</h1>
      <p>Band Bacon is a music visualization tool that helps you explore connections between artists and bands.</p>
    </div>
  );
}

export default About;
